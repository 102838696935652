<template>
  <div class="module-one list-scroll" v-infinite-scroll="loadMore">
    <template v-if="list.length > 0">
      <div class="list-item" v-for="l in list" :key="l.id">
        <div class="list-item-left">
          <img :src="baseUrl + (l.img && l.img.file_path)" alt="" />
        </div>
        <div class="list-item-right">
          <div class="item-name scroll-hidden">{{ l.name }}</div>
          <div class="item-mes list-scroll">{{ l.mes }}</div>
          <div class="item-btns">
            <span @click="toDetail(l)">阅读全文</span>
            <!-- <span>下载文档</span> -->
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="empty-box">
        <img src="@/assets/images/empty.jpg" alt="" />
        <p>暂无数据</p>
      </div>
    </template>
  </div>
</template>

<script>
import { getModuleBaseList } from "@/api/base.js";
export default {
  name: "ModuleOne",
  data() {
    return {
      loadingList: false,
      searchObj: {
        page: 1,
        pageSize: 20,
        moduleCode: "",
        typeId: 0,
      },
      list: [],
      total: 0,
    };
  },
  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler(val) {
        // console.log("val", val);
        const { query } = val;
        this.searchObj.moduleCode = query.module_code;
        this.searchObj.typeId = query.id;
      },
    },
    "searchObj.typeId"() {
      this.getModuleBaseList();
    },
  },
  created() {
    // console.log("created", this.searchObj);
    this.getModuleBaseList();
  },
  methods: {
    getModuleBaseList(loadMore = false) {
      if (!loadMore) {
        this.searchObj.page = 1;
        this.list = [];
      }
      this.loadingList = true;
      let params = {
        pageindex: this.searchObj.page,
        pagesize: this.searchObj.pageSize,
        module_code: this.searchObj.moduleCode,
        type_id: this.searchObj.typeId,
      };
      getModuleBaseList(params)
        .then((res) => {
          // console.log(res);
          if (res.code === 200) {
            const { data } = res;
            this.list = this.list.concat(data.data);
            this.total = data.dataCount;
          }
        })
        .finally(() => {
          this.loadingList = false;
        });
    },
    loadMore() {
      if (this.list.length < this.searchObj.page * this.searchObj.pageSize) {
        return;
      }
      this.searchObj.page += 1;
      this.getModuleBaseList(true);
    },
    toDetail(item) {
      this.$emit("clickItem", item.name);

      this.$router.push({
        name: "DetailOne",
        query: {
          module_code: this.searchObj.moduleCode,
          id: this.searchObj.typeId,
          cid: item.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 769px) {
  .module-one {
    align-content: flex-start;
    .list-item {
      margin-top: 20px;
      width: 46%;
      height: 136px;
      margin-right: 4%;

      &:nth-child(2n) {
        margin-right: 0;
      }
      &:last-child {
        margin-bottom: 20px;
      }

      .item-mes {
        height: calc(136px - 36px - 28px);
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .module-one {
    .list-item {
      margin-top: 10px;
      width: 100%;
      height: 136px;
      &:last-child {
        margin-bottom: 10px;
      }

      .item-mes {
        height: calc(136px - 36px - 28px);
      }
    }
  }
}
.module-one {
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  .list-item {
    display: flex;
    background: #d5ebff;

    .list-item-left {
      width: 96px;
      background: #fff;
      border: 1px solid #e4e4e4;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .list-item-right {
      width: calc(100% - 96px);
      display: flex;
      flex-direction: column;
      position: relative;

      .item-name {
        height: 36px;
        line-height: 36px;
        padding: 0 10px;
        font-weight: 700;
        overflow-x: scroll;
        white-space: nowrap;
      }

      .item-mes {
        padding: 0 10px 10px;
        font-size: 12px;
        font-family: SourceHanSansCN;
        font-weight: 400;
        color: #585858;
        overflow: auto;
      }

      .item-btns {
        width: 100%;
        height: 28px;
        line-height: 28px;
        display: flex;
        font-size: 14px;
        color: #393939;
        position: absolute;
        bottom: 0;

        span {
          flex: 1;
          // width: 50%;
          background: #f2b624;
          text-align: center;
          cursor: pointer;

          &:first-child {
            background: #53b1f9;
            color: #fff;
          }
        }
      }
    }
  }
}
</style>